import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { Container, Text, Image } from "@atoms";
import { m, useScroll, useTransform } from "framer-motion";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const GrowingImage = ({
  heading,
  headingAlign,
  image,
  children,
  smallerSpacer,
  hideImage,
  darker,
}) => {
  const lang = useLang();
  const outerProgress = useRef(null);
  const [filterTrigger, setFilterTrigger] = useState(false);

  const { scrollYProgress } = useScroll({
    target: outerProgress,
    offset: ["start 0.25", "end end"],
  });

  // Portal Image
  const scale = useTransform(scrollYProgress, [0.1, 0.5], [0.2, 1.25]);
  const fadeIn = useTransform(scrollYProgress, [0, 0.15], [0.5, 1]);

  // Content
  // const copyOpacity = useTransform(scrollYProgress, [0.5, 0.75], [0, 1]);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(v => {
      setFilterTrigger(f => {
        if (v > 0.5 && !f) {
          return true;
        }
        if (f && v <= 0.5) {
          return false;
        }
        return f;
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="relative z-10 min-h-[150vh] pt-8">
      {/* portal logic + content */}
      <div
        className="absolute left-0 right-0 top-0 h-[150vh] -translate-y-[25vh] sm:translate-y-0"
        ref={outerProgress}
      />
      {/* image */}
      <div className="absolute h-[calc(100%-2rem)] w-full">
        <div
          className={classNames("sticky top-0 min-h-screen w-full pt-10", {
            // "overflow-x-hidden overflow-y-visible": filterTrigger,
          })}
        >
          <div className="absolute inset-0 overflow-hidden">
            {image?.attribution && (
              <div
                className={classNames(
                  "pointer-events-none absolute right-0 top-0 z-20 flex h-screen max-w-xs flex-col items-end justify-end gap-1 p-6 text-white transition delay-300 duration-300 md:p-14",
                  { "opacity-0": !filterTrigger || hideImage }
                )}
              >
                {image.attribution && (
                  <Text variant="caption">
                    <span>{t("Photo courtesy of", lang)}</span>
                    <span>{image.attribution}</span>
                  </Text>
                )}
              </div>
            )}
            <m.div
              style={{ scale, opacity: fadeIn }}
              className="texture-pattern absolute inset-0 w-full overflow-hidden rounded-t-full bg-black sm:-top-[15rem] sm:aspect-portal lg:-top-[20rem]"
            >
              <div className="absolute relative inset-0 h-full w-full overflow-hidden rounded-t-full">
                {/* color image */}
                <m.div
                  className={classNames(
                    "absolute inset-0 overflow-hidden rounded-t-full bg-black transition-opacity duration-1000",
                    {
                      "opacity-0": filterTrigger || hideImage,
                    }
                  )}
                >
                  <Image image={image} fill />
                </m.div>
                {/* b/w */}
                <m.div
                  className={classNames(
                    "absolute inset-0 overflow-hidden rounded-t-full bg-black transition-opacity duration-300",
                    {
                      "opacity-20": filterTrigger && !hideImage && darker,
                      "opacity-40": filterTrigger && !hideImage && !darker,
                      "opacity-0": !filterTrigger || hideImage,
                    }
                  )}
                >
                  <Image image={image} ixParams={{ sat: -100 }} fill />
                </m.div>
              </div>
            </m.div>
          </div>
        </div>
      </div>
      {/* heading */}
      <m.div className="flex h-[75vh] flex-col pb-14">
        <div
          className={classNames({
            "h-[15vh]": smallerSpacer,
            "h-[40vh]": !smallerSpacer,
          })}
        />
        <Container frame className="sticky top-[50vh]">
          <Text
            variant="h2"
            className={classNames(
              "max-w-2xl text-white transition duration-500",
              {
                "text-left": headingAlign === "left",
                "mx-auto text-center": headingAlign === "center",
              }
            )}
          >
            {heading}
          </Text>
        </Container>
      </m.div>
      {/* content */}
      <div
        className={classNames(
          "sticky top-8 z-[100] flex flex-col justify-center pb-24 transition duration-500 md:pb-28",
          {
            "opacity-0": !filterTrigger,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

GrowingImage.defaultProps = {
  headingAlign: "left",
};

export default GrowingImage;
